
import { Vue, Component, Prop } from 'vue-property-decorator';
import DataTableHandler from '@/components/DataTable/DataTable';

@Component({
  name: 'SelectFilter',
})
export default class SelectFilter extends Vue {
  @Prop({ required: true })
  private readonly dataTable!: DataTableHandler;

  @Prop({ required: true })
  private readonly columnName!: string;

  private inputValue: number|null|undefined = null;

  private mounted(): void {
    this.resetInputValue(); // Solution to make variable undefined without getting errors
  }

  private openOptions(): void {
    const autocompleteElement = this.$refs.autocomplete as Vue & { $el: () => Vue };
    const inputElements = autocompleteElement.$el.getElementsByClassName('v-input__slot');
    const inputElement = inputElements.item(0) as null|{ click: () => void };

    if (inputElement) {
      inputElement.click();
      this.resetInputValue();
    }
  }

  private resetInputValue(): void {
    this.inputValue = undefined;
  }

  private getFilterOptions(columnName: string): Array<{ text: string, value: unknown }> {
    const options = this.dataTable.getFilter(columnName).metadata?.options as Array<{const: unknown, title: string }>;

    return options.map((item) => ({ text: item.title, value: item.const }));
  }

  private applyFilter(): void {
    this.dataTable.getFilter(this.columnName).data = this.inputValue;
    this.dataTable.getFilter(this.columnName).applied = this.inputValue !== undefined;
    this.$emit('fetchData');
  }
}
